<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "line-spin-fade" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<div class="container">
  <div class="row">
    <div class="col-xs-4">
      <form [formGroup]="displayForm" >
        <div class="form-group">
          <label for="display">Filter by contact group</label>
          <select class="form-control lengthenSelect"  id="display" formControlName="display" style="font-size: 16px;">
            <option *ngFor="let option of selectOps" [value]="option">{{option}}</option>
          </select>
        </div>
      </form>
    </div>
  </div>
  <hr>
  <div class="row">
    <div class="col-xs-12">
      <ul class="list-group">
        <a
          class="list-group-item"
          *ngFor="let contact of contactsList | relation:this.filter:'contactGroup'"
          style="cursor: pointer; "
        >
          <b><u>Name:</u></b>
          <span class="pull-right">
            <button class="btn btn-danger" (click)="onDelete(contact)">Delete</button> &nbsp; <button class="btn btn-primary" (click)="editCalled(contact)">Edit</button>
          </span>
          <div>{{contact.name}}</div>
          <span class="pull-right"><b>{{contact.contactGroup}}</b></span>
          <div>&nbsp;</div>
          <b><u>Numbers:</u></b>
          <div *ngIf="contact.contact1 !== null && contact.contact1.length !== 0 && contact.contact1 !== undefined">Landline: {{contact.contact1}} </div>
          <div *ngIf="contact.contact2 !== null && contact.contact2.length !== 0 && contact.contact2 !== undefined">Mobile: {{contact.contact2}} </div>
          <div *ngIf="contact.contact3 !== null && contact.contact3.length !== 0 && contact.contact3 !== undefined">Other: {{contact.contact3}} </div>
        </a>
      </ul>
    </div>
  </div>
</div>
