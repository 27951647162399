import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MsalBroadcastService, MsalService} from '@azure/msal-angular';
import {filter} from 'rxjs/operators';
import {EventMessage, EventType, InteractionStatus} from '@azure/msal-browser';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  constructor(private router: Router, private authService: MsalService, private msalBroadcastService: MsalBroadcastService) { }

  loginDisplay = false;

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.router.navigate(['/directory']);
    }
  }


}
