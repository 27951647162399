import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService} from '@azure/msal-angular';
import {filter, takeUntil} from 'rxjs/operators';
import {InteractionStatus, RedirectRequest} from '@azure/msal-browser';
import {Subject} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ContactsService} from '../new-contacts/contacts.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

export type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  loginDisplay = false;

  private readonly _destroying = new Subject<void>();

  getApi = new Subject<void>();

  initialGetApi = false;

  profile: ProfileType;

  constructor(private router: Router,
              private authService: MsalService,
              private broadcastService: MsalBroadcastService,
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private http: HttpClient,
              private contactsService: ContactsService) { }


  ngOnInit(): void {
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });

    this.getApi.subscribe(() => {
      this.getProfile();
    });

    if (this.loginDisplay) {
      this.getProfile();
    }

  }

  onLogout(): void {
    this.router.navigate(['/auth']);
  }

  login(): void {
    if (this.msalGuardConfig.authRequest){
      this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    if (this.loginDisplay) {
      this.getApi.next(undefined);
    }
  }

  getProfile(): void {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.profile = profile;
        this.contactsService.userId = this.profile.id;
        this.contactsService.userIdSubject.next(this.profile.id);
        if (this.contactsService.getting === true) {
          this.contactsService.setArray();
          this.contactsService.changeGetting();
        }
      });
  }

  logout(): void {
    this.authService.logoutRedirect({
      postLogoutRedirectUri: 'https://my.phone-book.cloud/#/auth'
    });
  }

  ngOnDestroy(): void {
    this._destroying.next(undefined);
    this._destroying.complete();
  }


}
