import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactsService} from './contacts.service';
import {HttpClient} from '@angular/common/http';
import {NgxSpinnerService} from 'ngx-spinner';
import {ContactModel} from './contact.model';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';


@Component({
  selector: 'app-new-contacts',
  templateUrl: './new-contacts.component.html',
  styleUrls: ['./new-contacts.component.css']
})
export class NewContactsComponent implements OnInit {

  contactForm: FormGroup;

  editForm: FormGroup;

  backup = '';

  con: boolean;



  showSpinner: boolean;


  editing: boolean;

  valueContact: ContactModel;













  onSubmit(): void {
    if (this.contactForm.invalid) {
      this.contactForm.reset({relation: 'Work'});
    }
    else {
      this.checkContacts();
      this.createNewContact();
      this.contactForm.reset({relation: 'Work'});
    }
  }

  onEditSubmit(): void {
    if (this.editForm.invalid) {
      this.editForm.reset({
        name: this.valueContact.name,
        num1: this.valueContact.contact1,
        num2: this.valueContact.contact2,
        num3: this.valueContact.contact3,
        rel: this.valueContact.contactGroup
      });
    }
    else {
      this.onEdit();
      this.contactsService.editMode = false;
      this.contactsService.editModeChanged.next(this.contactsService.editMode);
      this.router.navigate(['/directory']);
    }
  }


  constructor(private contactsService: ContactsService, private http: HttpClient, private spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit(): void {







    this.editing = this.contactsService.getEditing();
    this.contactsService.editModeChanged.subscribe((edit: boolean) => {
      this.editing = edit;
    });





    this.showSpinner = this.contactsService.returnSpinner();
    this.contactsService.changeSpinner
      .subscribe((bool: boolean) => {
        this.showSpinner = bool;
        if (this.showSpinner === true) {
          this.spinner.show();
        } else {
          this.spinner.hide();
        }
      });


    if (!this.editing) {
      this.contactForm = new FormGroup({
        name1: new FormControl(null, [Validators.required]),
        relation: new FormControl('Work'),
        number1: new FormControl('', [Validators.required]),
        number2: new FormControl(''),
        number3: new FormControl('')
      });
    }
    if (this.editing) {
      this.editForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        rel: new FormControl('Work'),
        num1: new FormControl('', [Validators.required]),
        num2: new FormControl(''),
        num3: new FormControl('')
      });
    }




    this.valueContact = this.contactsService.getContact();
    this.contactsService.subContact.subscribe((contact: ContactModel) => {
      this.valueContact = contact;
    });




    if (this.editing) {
      this.editForm.setValue({
        name: this.valueContact.name,
        num1: this.valueContact.contact1,
        num2: this.valueContact.contact2,
        num3: this.valueContact.contact3,
        rel: this.valueContact.contactGroup
      });
    }



    if (!this.editing) {
      this.contactsService.contact = null;
      this.contactsService.subContact.subscribe((contact: ContactModel) => {
        this.valueContact = contact;
      });
    }




    if (this.contactsService.getting && this.contactsService.userId !== '') {
      this.contactsService.setArray();
      this.contactsService.changeGetting();
    }

  }




  createNewContact(): void
  {
    this.checkContacts();
    const contact: ContactModel = {name: this.contactForm.get('name1').value, contact1: this.contactForm.get('number1').value,
    contact2: this.contactForm.get('number2').value, contact3: this.contactForm.get('number3').value,
      contactGroup: this.contactForm.get('relation').value};
    this.contactsService.addContacts(contact);
  }




  checkContacts(): void {
    if (!this.editing) {
      if (this.contactForm.get('number2').value === null) {
        this.contactForm.patchValue({
          number2: this.backup
        });
      }
      if (this.contactForm.get('number3').value === null) {
        this.contactForm.patchValue({
          number3: this.backup
        });
      }
    }

    if (this.editing) {
      if (this.editForm.get('num2').value === null) {
        this.editForm.patchValue({
          num2: this.backup
        });
      }
      if (this.editForm.get('num3').value === null) {
        this.editForm.patchValue({
          num3: this.backup
        });
      }
    }
  }


  onEdit(): void {
    this.checkContacts();
    this.contactsService.editContact(this.editForm.get('name').value,
      this.editForm.get('num1').value, this.editForm.get('num2').value, this.editForm.get('num3').value,
      this.editForm.get('rel').value, this.valueContact.contactId, this.contactsService.contacts.indexOf(this.valueContact),
      this.valueContact.rowVersion);
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.editing) {
      return true;
    }
    else {
      this.con = confirm('Are you sure you want to leave the page?');
      if (this.con === true) {
        this.contactsService.editMode = false;
        return true;
      }
      else {
        this.router.navigate(['/contacts/edit']);
      }
     // return confirm('Are you sure you want to leave the page?');
    }
  }





}
