import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NewContactsComponent} from './new-contacts/new-contacts.component';
import {ContactsListComponent} from './contacts-list/contacts-list.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {AuthComponent} from './auth/auth.component';
import {RouteGuardService} from './route-guard.service';
import {CanDeactivateGuardService} from './new-contacts/canDeactivateGuard.service';
import {MsalGuard} from '@azure/msal-angular';
import {InfoComponent} from './info/info.component';



const appRoutes: Routes = [
  {path: '', redirectTo: '/auth', pathMatch: 'full'},
  {path: 'contacts', component: NewContactsComponent, canActivate: [MsalGuard], canDeactivate: [CanDeactivateGuardService]},
  {path: 'contacts/edit', component: NewContactsComponent, canActivate: [MsalGuard, RouteGuardService],
    canDeactivate: [CanDeactivateGuardService]},
  {path: 'directory', component: ContactsListComponent, canActivate: [MsalGuard]},
  {path: 'info', component: InfoComponent, canActivate: [MsalGuard]},
  {path: 'auth', component: AuthComponent},
  {path: 'not-found', component: PageNotFoundComponent},
  {path: '**', redirectTo: '/not-found'}
];


const isIframe = window !== window.parent && !window.opener;



@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {useHash: true, initialNavigation: !isIframe ? 'enabled' : 'disabled'})
  ],

  exports: [RouterModule]
})

export class AppRoutingModule {

}
