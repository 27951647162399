import { Component, OnInit } from '@angular/core';
import {ContactsService} from '../new-contacts/contacts.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  userId = '';

  constructor(private contactsService: ContactsService) { }

  ngOnInit(): void {
    this.userId = this.contactsService.returnUserId();
    this.contactsService.userIdSubject.subscribe(id => {
      this.userId = id;
    });
  }

}
