import {Injectable} from '@angular/core';
import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {ContactsService} from '../new-contacts/contacts.service';

@Injectable()

export class ConcurrencyInterceptorService implements HttpInterceptor{


  constructor(private contactsService: ContactsService) {
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): any {

    return next.handle(req).pipe(tap(request => {}, err => {
      this.contactsService.error = err.status;
      if (this.contactsService.error === 409) {
        alert('Someone has already modified this file, press OK to refresh');
        this.contactsService.contacts = [];
        this.contactsService.contactsChanged.next(this.contactsService.contacts.slice());
        this.contactsService.setArray();
        console.log('Caught by interceptor!!!');
      }
      if (this.contactsService.error === 500) {
        alert('The server is currently building, it will be online shortly, press OK to continue');
        this.contactsService.contacts = [];
        this.contactsService.contactsChanged.next(this.contactsService.contacts.slice());
        this.contactsService.setArray();
        console.log('500 caught by interceptor!');
      }
    }));
  }

  // This is a pointless comment.

}
