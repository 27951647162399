import {Component, OnInit} from '@angular/core';
import {ContactsService} from '../new-contacts/contacts.service';
import {ContactModel} from '../new-contacts/contact.model';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {HttpClient} from '@angular/common/http';




@Component({
  selector: 'app-contacts-list',
  templateUrl: './contacts-list.component.html',
  styleUrls: ['./contacts-list.component.css']
})
export class ContactsListComponent implements OnInit {

  constructor(private contactsService: ContactsService, private router: Router,
              private spinner: NgxSpinnerService, private http: HttpClient
              ) { }



  contactsList: ContactModel[];







  filter = 'Any';

  displayForm: FormGroup;

  showSpin: boolean;


  selectOps: string[] = [
    'Any',
    'Work',
    'Friends',
    'Family'
  ];

















  ngOnInit(): void {

    // this.getProfile();

    this.showSpin = this.contactsService.returnSpinner();
    this.contactsService.changeSpinner
      .subscribe((bool: boolean) =>
      {
        this.showSpin = bool;
        if (this.showSpin === true)
        {
          this.spinner.show();
        }
        else
          {
          this.spinner.hide();
        }
      });

    this.displayForm = new FormGroup({display: new FormControl('Any')});



    this.displayForm.get('display').valueChanges.subscribe(selectedValue =>
    {
      this.filter = selectedValue;
    });


    this.contactsList = this.contactsService.getContacts();
    this.contactsService.contactsChanged
      .subscribe((contacts: ContactModel[]) =>
      {
        this.contactsList = contacts;
      });

    if (this.contactsService.getting === true && this.contactsService.userId !== '')
    {
      this.contactsService.setArray();
      this.contactsService.changeGetting();
    }



    this.contactsService.resetContact();





  }

  editCalled(contact: ContactModel): void
  {
    this.contactsService.editMode = true;
    this.contactsService.editModeChanged.next(this.contactsService.editMode);
   // const id = contact.contactId;
   // const index = this.contactsService.contacts.indexOf(contact);
   // const row = contact.rowVersion;
   // this.router.navigate(['/edit/' + id + '/' + index + '/' + row]);
    this.contactsService.contact = contact;
    this.contactsService.subContact.next(this.contactsService.contact);
    this.router.navigate(['/contacts/edit']);
  }



  onDelete(contact: ContactModel): void
  {
    const id = contact.contactId;
    const index = this.contactsService.contacts.indexOf(contact);
    const row = contact.rowVersion;
    console.log(contact.rowVersion);
    this.contactsService.deleteData(id, index, row);
   }












}
