import {HttpEventType, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ContactsService} from '../new-contacts/contacts.service';

@Injectable()
export class ResponseInterceptorService implements HttpInterceptor{

  constructor(private contactsService: ContactsService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(req).pipe(tap(event => {
      if (event.type === HttpEventType.Response) {
        this.contactsService.changeSpinnerBack();
      }
    }));
  }
}
