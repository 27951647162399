import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {NewContactsComponent} from './new-contacts/new-contacts.component';
import {ContactsListComponent} from './contacts-list/contacts-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './header/header.component';
import {ContactsService} from './new-contacts/contacts.service';
import {RouteGuardService} from './route-guard.service';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {RelationPipe} from './relation.pipe';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ResponseInterceptorService} from './contacts-list/response-interceptor.service';
import {SentInterceptorService} from './contacts-list/sent-interceptor.service';
import {AuthComponent} from './auth/auth.component';
import {ConcurrencyInterceptorService} from './contacts-list/concurrency-interceptor.service';
import {CanDeactivateGuardService} from './new-contacts/canDeactivateGuard.service';
import {MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent} from '@azure/msal-angular';
import {InteractionType, PublicClientApplication} from '@azure/msal-browser';
import { InfoComponent } from './info/info.component';


const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;



@NgModule({
  declarations: [
    AppComponent,
    NewContactsComponent,
    ContactsListComponent,
    HeaderComponent,
    PageNotFoundComponent,
    RelationPipe,
    AuthComponent,
    InfoComponent,


  ],
    imports: [
      BrowserModule,
      AppRoutingModule,
      ReactiveFormsModule,
      FormsModule,
      HttpClientModule,
      NgxSpinnerModule,
      BrowserAnimationsModule,
      MsalModule.forRoot(new PublicClientApplication({
        auth: {
          clientId: '7fc335f1-32d3-4ae2-90d7-192106aa579a',
          authority: 'https://login.microsoftonline.com/7b40e596-dcf9-49c9-ad7e-ad23cd18ebc8',
          redirectUri: 'https://my.phone-book.cloud'
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE
        }
      }), {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ['user.read']
        }
      }, {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          ['https://graph.microsoft.com/v1.0/me', ['user.read']]
        ])
      })


    ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [ContactsService, RouteGuardService,
    {provide: HTTP_INTERCEPTORS, useClass: SentInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ConcurrencyInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true},
    CanDeactivateGuardService,
    MsalGuard
    ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }
