<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header">
      <a  class="navbar-brand" href="/">Address Book</a>
    </div>

    <div class="navbar-default">
      <ul class="nav navbar-nav">
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/directory']">Directory</a> </li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/contacts']">Add new contact</a></li>
        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a [routerLink]="['/info']">Phone Book Info</a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li>
          <a style="cursor: pointer" role="button" *ngIf="!loginDisplay" (click)="login()">Login</a>
          <a style="cursor: pointer" role="button" *ngIf="loginDisplay" (click)="logout()">Logout</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
