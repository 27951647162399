import {Injectable} from '@angular/core';
import {ContactsService} from './new-contacts/contacts.service';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class RouteGuardService implements CanActivate{

  constructor(private contactsService: ContactsService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const promise = new Promise<any>((resolve, reject) => {
      if (this.contactsService.editMode === true) {
        resolve(true);
      } else {
        resolve(this.router.navigate(['/xyz']));
      }
    });
    return promise;
  }

}
