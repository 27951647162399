<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "line-spin-fade" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>
<div class="container">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-8 col-sm-offset-1 col-md-offset-2">
      <div *ngIf="!editing">
        <form (ngSubmit)="onSubmit()" [formGroup]="contactForm">
          <h3>Name of contact</h3>
          <hr>
          <div class="form-group">
            <label for="name1">Name</label>
            <input class="form-control" formControlName="name1" id="name1" placeholder="Enter full name">
            <span *ngIf="!contactForm.get('name1').valid && contactForm.get('name1').touched"
            class="help-block">
              <span *ngIf="contactForm.get('name1').errors['required']">This field is required!</span>
            </span>
          </div>
          <hr>
          <h3>Contact group</h3>
          <hr>
          <div class="form-group">
            <label for="selection">Contact group</label>
            <select class="form-control" formControlName="relation" id="selection">
              <option value="Work">Work</option>
              <option value="Friends">Friends</option>
              <option value="Family">Family</option>
            </select>
          </div>
          <hr>
          <h3>Contact numbers</h3>
          <hr>
          <div  class="form-group">
            <label  for="number1">Landline</label>
            <input  class="form-control" id="number1" formControlName="number1" placeholder="Enter contact number">
            <span *ngIf="!contactForm.get('number1').valid && contactForm.get('number1').touched"
                  class="help-block">
              <span *ngIf="contactForm.get('number1').errors['required']">This field is required!</span>
            </span>
          </div>
          <hr>
          <div class="form-group">
            <label for="number2">Mobile</label>
            <input class="form-control" formControlName="number2" id="number2" placeholder="Enter contact number">
          </div>
          <hr>
          <div class="form-group">
            <label for="number3">Other</label>
            <input class="form-control" id="number3" formControlName="number3" placeholder="Enter contact number">
          </div>
          <div class="form-group">
            <button
                    [disabled]="this.contactForm.invalid"
                    [ngClass]="this.contactForm.invalid? 'btn btn-danger': 'btn btn-success'"
                    type="submit">Add new contact</button>
          </div>
        </form>
      </div>
      <div *ngIf="editing">
        <form (ngSubmit)="onEditSubmit()" [formGroup]="editForm">
          <h3>Name of contact</h3>
          <hr>
          <div class="form-group">
            <label for="name">Name</label>
            <input class="form-control" formControlName="name" id="name" placeholder="Enter new name">
            <span *ngIf="!editForm.get('name').valid && editForm.get('name').touched"
            class="help-block">
              <span *ngIf="editForm.get('name').errors['required']">This field is required!</span>
            </span>
          </div>
          <hr>
          <h3>Contact group</h3>
          <hr>
          <div class="form-group">
            <label for="selectionEdit">Contact group</label>
            <select class="form-control" formControlName="rel" id="selectionEdit">
              <option value="Work">Work</option>
              <option value="Friends">Friends</option>
              <option value="Family">Family</option>
            </select>
          </div>
          <hr>
          <h3>Contact numbers</h3>
          <hr>
          <div  class="form-group">
            <label  for="num1">Landline</label>
            <input  class="form-control" id="num1" formControlName="num1" placeholder="Enter contact number">
            <span *ngIf="!editForm.get('num1').valid && editForm.get('num1').touched"
                  class="help-block">
              <span *ngIf="editForm.get('num1').errors['required']">This field is required!</span>
            </span>
          </div>
          <hr>
          <div class="form-group">
            <label for="num2">Mobile</label>
            <input class="form-control" formControlName="num2" id="num2" placeholder="Enter contact number">
          </div>
          <hr>
          <div class="form-group">
            <label for="num3">Other</label>
            <input class="form-control" id="num3" formControlName="num3" placeholder="Enter contact number">
          </div>
          <div class="form-group">
            <button
                    [disabled]="this.editForm.invalid"
                    class="btn btn-primary"
                    type="submit">Save changes</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
