import {ContactModel} from './contact.model';
import {Injectable, EventEmitter} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';






@Injectable()
export class ContactsService {

  constructor(private http: HttpClient) {
  }





  error = null;

  subContact = new Subject<ContactModel>();

  contact: ContactModel = null;

  showSpinner = false;

  changeSpinner = new EventEmitter<boolean>();

  userId = '';

  userIdSubject = new Subject<string>();


  contactsChanged = new Subject<ContactModel[]>();

  contacts: ContactModel[] = [];

  editModeChanged = new Subject<boolean>();

  editMode = false;



  getting = true;


  getEditing(): boolean {
    return this.editMode;
  }


  getContact(): ContactModel {
    return this.contact;
  }


  changeGetting(): void {
    this.getting = false;
  }

  returnUserId(): string {
    return this.userId;
  }



  changeSpinnerStatus(): void {
    this.showSpinner = true;
    this.changeSpinner.emit(this.showSpinner);
  }

  changeSpinnerBack(): void {
    this.showSpinner = false;
    this.changeSpinner.emit(this.showSpinner);
  }

  resetContact(): void {
    this.contact = null;
    this.subContact.next(this.contact);
  }

  returnSpinner(): boolean {
    return this.showSpinner;
  }

  setArray(): void {
    this.getData().subscribe(responseData => {
      for (const contact in responseData) {
        if (responseData.length >= 0) {
          if (responseData[contact].contact2 === undefined) {
            responseData[contact].contact2 = '';
          }
          if (responseData[contact].contact3 === undefined) {
            responseData[contact].contact3 = '';
          }
          this.contacts.push(responseData[contact]);
        }

      }
      this.sortContacts(this.contacts);
      this.contactsChanged.next(this.contacts.slice());
    });

  }


  getContacts(): ContactModel[] {
    return this.contacts.slice();
  }


  toContacts(contact: ContactModel): Observable<ContactModel> {
    const userId = this.userId;
    return this.http.post<ContactModel>('https://api.phone-book.cloud/v1/contact/' + userId,
      contact);
  }


  addContacts(contact: ContactModel): void {
    this.toContacts(contact).subscribe(responseData => {
      console.log(responseData);
      this.contacts.push(responseData);
      this.sortContacts(this.contacts);
      this.contactsChanged.next(this.contacts.slice());
    });
  }


  getData(): Observable<ContactModel[]> {
    const userId = this.userId;
    return this.http.get<ContactModel[]>('https://api.phone-book.cloud/v1/contact/' + userId
    );
  }



  deleteData(id: string, index: number, row: string): void {
    const userId = this.userId;
    this.http.delete('https://api.phone-book.cloud/v1/contact/' + userId + '/' + id + '/' + row,
      {
        observe: 'response'
      }
    ).subscribe();
    this.contacts.splice(index, 1);
    this.sortContacts(this.contacts);
    this.contactsChanged.next(this.contacts.slice());
  }

  editContact(name1: string,
              number1: string,
              number2: string,
              number3: string,
              relation: string,
              i: string,
              index: number,
              row: string): void
  {
    const contact: ContactModel = {name: name1, contact1: number1, contact2: number2, contact3: number3, contactGroup: relation,
      rowVersion: row};
    const userId = this.userId;
    this.http.put<ContactModel>('https://api.phone-book.cloud/v1/contact/' + userId + '/' + i,
      contact).subscribe(
        data => {
          this.contacts[index] = data;
          this.sortContacts(this.contacts);
          this.contactsChanged.next(this.contacts.slice());
        }
    );
    this.contact = null;
    this.subContact.next(this.contact);
  }



  sortContacts(contacts: ContactModel[]): ContactModel[] {
    return contacts.sort((a, b) => {
      const nm = a.name.toLowerCase();
      const nm2 = b.name.toLowerCase();
      if (nm > nm2) {
        return 1;
      } else {
        return -1;
      }
    });
  }




}
